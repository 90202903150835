@import url(https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700);
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


/* Logo */

.Logo {
  display: flex;
  align-items: center;
  margin-right: auto;
  grid-gap: 10px;
  gap: 10px;
}

.Logo img {
  width: 34px;
  height: 34px;
  left: 0px;
  top: 8px;
}

.Logo span {
  width: 133px;
  height: 45px;
  left: 44px;
  top: 0px;
  grid-gap: 10px;
  gap: 10px;
  
  font-family: "DM Serif Display";
  color: #292f36;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 133%;
}

.Header {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  flex-direction: row;
  padding: 0px;
  grid-gap: 399px;
  gap: 399px;
  width: 1200px;
  height: 50px;
  left: 360px;
  margin-top: 40px;
}

nav {
  display: flex;
  align-items: center;
  cursor: pointer;
  grid-gap: 31px;
  gap: 31px;
}

.nav-item {
  margin-right: 31px;
  text-decoration: none;
  color: #292f36;
}

.Menubar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  width: 572px;
  height: 25px;
  left: 0px;
  top: 0px;

  font-family: "Jost";
  color: #292f36;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 125%;
}

.search {
  width: 21px;
  height: 21px;

  opacity: 0.8;
}

/* Home */
/* Base styles for larger screens */
.Home .Banner {
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Ensure space between the image and content */
  align-items: center;
  padding: 20px; /* Add padding for better spacing */
  overflow: hidden; /* Prevent overflow issues */
}

.Home .Banner .img {
  flex: 1 1; /* Make the image section take up 50% width */
  max-width: 100%; /* Ensure image scales correctly */
}

.Home .Banner .img img {
  max-width: 100%;  /* Image will scale down */
  height: auto;     /* Maintain aspect ratio */
  min-width: 300px; /* Set a minimum width so the image doesn't shrink too much */
}

.Home .Banner .content {
  flex: 1 1; /* Make content section take up 50% width */
  text-align: center; /* Center the text */
  margin: 10px;
}

.Home .Banner .content .s-t {
  font-size: 3rem;  /* Font size for larger screens */
  line-height: 1.2;
  margin: 20px 0;
}

.Home .Banner .content .Button {
  padding: 1rem 2rem; /* Button padding */
  font-size: 1.2rem;  /* Button text size */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

/* Medium screens (Tablets) */
@media (max-width: 768px) {
  .Home .Banner {
    flex-direction: column; /* Stack image and content vertically on tablets */
    text-align: center;
  }

  .Home .Banner .img {
    width: 90%; /* Reduce image width */
    margin: 0 auto; /* Center the image */
  }

  .Home .Banner .content .s-t {
    font-size: 2rem; /* Adjust font size for tablets */
  }

  .Home .Banner .content .Button {
    padding: 0.8rem 1.5rem; /* Adjust button padding */
    font-size: 1rem;        /* Reduce button text size */
  }
  
  .Footer-Section {
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.Footer-first, .Footer-Second {
    width: 100%; /* Makes each section take full width */
    margin-bottom: 1.5rem;
}

.footer-des, .Logo h3, .social a img {
    margin: 0 auto; /* Center elements on smaller screens */
}

.social {
    display: flex;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
}
}





/* Small screens (Mobile) */
@media (max-width: 480px) {
  .Home .Banner .img {
    width: 80%; /* Further reduce image width */
    min-width: 500px; /* Ensure image doesn't shrink too much */
    margin: 20px auto; /* Center the image */

  }

  .Home .Banner .content .s-t {
    font-size: 1.5rem; /* Further reduce font size for mobile */
    position: top;
    margin: 20px;
  }

  .Home .Banner .content .Button {
    padding: 0.3rem;  /* Smaller padding for mobile */
    font-size: 0.9rem;     /* Reduce button text size further */
    margin: 20px;
  }
  /* .Section{
    gap: 40px;
    margin: 20px;
    padding: 20px;
  } */
}


  /* CSS for section at 320px mobile view */
/* Mobile view adjustments for 320px width */
/* Enhanced mobile view adjustments for 320px width */
@media (max-width: 320px) {
  .Home, .Footer-Section, .Section, .Project-Section, .Counter, .About, .Banner {
      padding: 1rem 0.5rem;
      margin: 0;
      box-sizing: border-box;
      width: 100%;
  }

  /* General layout adjustments */
  .Banner, .About, .Project-Section {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-bottom: 1rem;
  }

  .content, .About-post, .Project-title, .footer-des, .social, .Counter {
      padding: 0.5rem 0;
      margin: 0 auto;
  }

  /* Project Section and Card Adjustments */
  .Project-Section .pros {
      display: flex;
      flex-direction: column;
      align-items: center;
      grid-gap: 1rem;
      gap: 1rem;
  }

  .Project-Section .pros div {
      width: 100%;
      margin-bottom: 1rem;
  }

  /* Counter section adjustments */
  .Counter {
      display: flex;
      flex-direction: column;
      align-items: center;
      grid-gap: 0.5rem;
      gap: 0.5rem;
      margin-bottom: 1.5rem;
  }
  .Counter .line {
      display: none;
  }

  /* Footer layout adjustments */
  .Footer-Section {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      grid-gap: 1rem;
      gap: 1rem;
      padding: 1rem 0;
  }

  .Footer-Section div {
      margin-bottom: 0.5rem;
      width: 100%;
  }

  /* Text and Image adjustments */
  .img img, .about-img img, .Project img, .Logo img {
      max-width: 100%;
      height: auto;
  }

  /* Button adjustments */
  .Content button, .about-call button {
      width: 100%;
      padding: 0.5rem;
      margin-top: 1rem;
  }

  /* Copyright text */
  .copyright {
      text-align: center;
      margin-top: 1rem;
      font-size: 0.8rem;
  }
}


.Home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.Banner {
  width: 100%;
  text-align: center;
  margin-bottom: 40px; /* Space between banner and sections */
}

.Banner .img img {
  width: 100%;
  height: auto;
}

.content {
  /* margin-top: 20px; */
  margin: 20px;
}

.Section {
  display: flex;
  flex-direction: column;
  grid-gap: 40px;
  gap: 40px; /* Adds space between each section */
  width: 100%;
  max-width: 1200px; /* Ensure the sections are centered and not too wide */
  
}

.Section .Section-item {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.img {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 70px;
  overflow: hidden;
}

.img img {
  width: 100%;
  height: auto;
}

.content {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  text-align: left;
  margin-left: 100px;
  width: 35%;

}

.s-t {
  width: 567px;
  height: 150px;
  font-family: "DM Serif Display";
  color: #292f36;
  font-style: normal;
  font-weight: 400;
  font-size: 73px;
  line-height: 100%;
}

.s-p {
  width: 553px;
  height: 66px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #4d5053;
  margin-bottom: 20px;
  margin-top: 20px;
}

.Button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 26px 48px;
  grid-gap: 10px;
  gap: 10px;
  width: 219.14px;
  height: 75px;
  background: #292f36;
  box-shadow: 0px 10px 20px 0px #c0c0c059;
  border-radius: 18px;
}

.Button span {
  color: #ffffff;
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 125%;
}

.Button img {
  width: 15.14px;
  height: 13.18px;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  grid-gap: 52px;
  gap: 52px;
  width: 100%;
  height: 225px;
  margin-top: 90px;
}

.section h2 {
  font-family: "DM Serif Display";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #292f36;
  line-height: 25%;
}

.section p {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: #4d5053;
  line-height: 150%;
  letter-spacing: 0.01em;
  width: 80%;
}

.section a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #4d5053;
  padding: 0px;
  grid-gap: 12px;
  gap: 12px;
  width: 120.14px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 15%;
}

.Section {
  display: flex;
  align-items: center;
  text-align: center;
  grid-gap: 50px;
  gap: 50px;
  margin-left: 130px;
  margin-right: 130px;
}

.home-section {
  display: flex;
}

.about-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  width: 169px;
  height: 64px;
}

.about-call {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  grid-gap: 15px;
  gap: 15px;
  width: 217px;
  height: 93px;
}

.call {
  width: 90px;
  height: 80px;
  left: 0px;
  top: 0px;
}

/* .abtsec{
  margin: 10px;
  padding: 10px;
} */

.About-content {
  margin-top: 40px;
  grid-gap: 40px;
  gap: 40px;
}

.about-title-class {
  width: 470px;
  height: 189px;
  font-family: "DM Serif Display";
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 125%;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #292f36;
}

.about-des-class {
  margin-top: 30px;
  height: 150%;
  margin-bottom: 0px;
  width: 410px;
}

.About {
  margin-top: 100px;
  width: 83%;
  display: flex;
  margin-left: 130px;
  margin-right: 130px;
}

.About-post {
  margin-top: 43px;
  margin-bottom: 43px;
}

.about-img img {
  width: 700px;
  height: 653px;
  top: 0px;
  margin-left: 89px;
  background: #c4c4c4;
  border-radius: 0px 326.5px 0px 123px;
}

.about-text span {
  width: 161px;
  height: 23px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #4d5053;
}

.about-text p {
  width: 159px;
  height: 31px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 100%;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #4d5053;
}


.Project-Section {
  margin-top: 100px;
  width: 83%;
  margin-left: 130px;
  margin-right: 130px;
}

.Project-title span {
  width: 100%;
  height: 63px;
  font-family: "DM Serif Display";
  font-style: normal;
  font-weight: 400;
  font-size: 45px;
  line-height: 125%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: 0.02em;
  color: #292f36;
}

.Project-title {
  position: relative;
  z-index: 1; /* Ensures the title appears on top of other elements */
  padding: 20px 0; /* Add padding around the title to separate it from other elements */
}

.Project-title p {
  margin-top: 30px;
  max-width: 90%; /* Set max-width to make it responsive on smaller screens */
  height: auto; /* Let height adjust based on content */
  font-family: "Jost", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #4d5053;
  margin-left: auto; /* Center-align text in its container */
  margin-right: auto;
}

@media (max-width: 768px) {
  .Project-title p {
      font-size: 18px; /* Adjust font size for smaller screens */
      margin-top: 15px; /* Adjust margin for smaller screens */
  }
}

@media (max-width: 480px) {
  .Project-title p {
      font-size: 16px; /* Further adjust for very small screens */
      padding: 0 10px; /* Add padding to avoid text touching edges on small screens */
  }
}

.project-container {
  width: 100%;
  margin-top: 20px;
}

.btw button {
  min-width: 93%;
  margin-left: 67px;
}

.project-info h3 {
  width: 184px;
  height: 31px;
  font-family: "DM Serif Display";
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 125%;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #292f36;
}

.project-info p {
  width: 210px;
  height: 27px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #4d5053;
}

.project-info {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Counter {
  margin: 0 auto; /* Center the counter container */
  position: relative;
  height: 457px;
  top: 90px;
  width: 100%;
  max-width: 1200px; /* Limit the max width for larger screens */
  background-color: #f4f0ec;
  display: flex;
  grid-gap: 30px;
  gap: 30px;
  justify-content: center;
  align-items: center;
  padding: 1rem; /* Add padding for spacing */
  flex-wrap: wrap;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
  border-radius: 8px; /* Optional: Add rounded corners */
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .Counter {
      height: auto; /* Allow the height to adjust on smaller screens */
      padding: 2rem 1rem; /* Increase padding for better spacing */
      grid-gap: 20px;
      gap: 20px; /* Reduce gap for smaller screens */
      top: 30px; /* Adjust top position if needed */
  }
}


.Counter-Pro span {
  width: 77px;
  height: 106px;
  font-family: "DM Serif Display";
  font-style: normal;
  font-weight: 400;
  font-size: 85px;
  line-height: 125%;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #cda274;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.Counter-Pro p {
  width: 242px;
  height: 33px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #4d5053;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.Counter-Pro {
  margin-top: 2%;
  align-items: center;
  align-content: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.line {
  width: 1px;
  height: 110px;
  margin-top: 190px;
}

.Footer-Home {
  position: absolute;
  top: 6620px;
}

.social {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  grid-gap: 54px;
  gap: 54px;
  width: 225px;
  height: 23px;
}

.footer-des {
  width: 350px;
  height: 66px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #4d5053;
  margin: 30px;
}

.Footer-first {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  grid-gap: 31px;
  gap: 31px;
  width: 393px;
  height: 188px;
}

.Footer-Section {
  display: flex;
  justify-content: center;
  grid-gap: 30px;
  gap: 30px;
  margin-top: 100px;
}

.Footer-Second span {
  width: 64px;
  height: 31px;
  font-family: "DM Serif Display";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 125%;
  display: flex;
  align-items: center;
  color: #292f36;
  margin-bottom: 17px;
}

.Footer-Second p {
  width: 250px;
  height: 78px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #4d5053;
}

.copyright {
  margin-top: 100px;
  width: 100%;
  height: 33px;
  left: 584px;
  top: 544px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  letter-spacing: 0.01em;
  color: #4d5053;
}

.Banner-wrap {
  margin-left: -230px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 41px 80px;
  grid-gap: 10px;
  gap: 10px;
  position: absolute;
  width: 544px;
  height: 178px;
  left: 708px;
  top: 239px;
  background: #ffffff;
  border-radius: 37px 37px 0px 0px;
}

.Banner-wrap span {
  width: 100%;
  height: 63px;
  font-family: "DM Serif Display";
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 125%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  color: #292f36;
}

.Banner-wrap p {
  width: 100%;
  height: 33px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 150%;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.01em;
  color: #4d5053;
}

.Banner-Prop {
  margin-top: 45px;
}

.Banner {
  margin-left: 130px;
  margin-right: 130px;
  margin-top: 45px;
}

.Footer {
  z-index: 100;
}

.justify-left {
  justify-content: flex-start;
}

.service-gap {
  margin-top: 100px;
}

.Services-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 160px 116px;
  grid-gap: 10px;
  gap: 10px;
  position: relative;
  width: 83%;
  height: 100%;
  margin-top: 100px;
  background: #f4f0ec;
  border-radius: 70px;
  margin-left: 130px;
  margin-right: 130px;
}

.Services-work {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 90px;
  grid-gap: 50px;
  gap: 50px;
}

.work-sr {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.Services-cont {
  display: flex;
  flex-direction: column;
  grid-gap: 100px;
  gap: 100px;
}

.ser-c-img {
  display: flex;
  justify-content: space-between;
}

.ser-c-img img {
  width: 92px;
  height: 92px;
}

.ser-c-img span {
  float: right;
  width: 107px;
  height: 102px;
  font-family: "DM Serif Display";
  font-style: normal;
  font-weight: 400;
  font-size: 120px;
  line-height: 125%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.ser-c-t {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.ser-c-t span {
  width: 288px;
  height: 44px;
  font-family: "DM Serif Display";
  font-style: normal;
  font-weight: 400;
  font-size: 33px;
  line-height: 125%;
  color: #292f36;
}

.ser-c-t p {
  width: 456px;
  height: 99px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #4d5053;
}

.serv-img img {
  border-radius: 0px 123px 0px 326.5px;
  width: 450.6px;
  height: 453px;
}

.Services-work:nth-child(odd) {
  flex-direction: row;
}
.Services-work:nth-child(even) {
  flex-direction: row-reverse;
}

.Project-sec {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  grid-gap: 61px;
  gap: 61px;
}

.Project-sec-1 {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-content: space-evenly;
  padding: 0px;
  grid-gap: 10px;
  gap: 10px;
  width: 680px;
  height: 65px;
  border: 1px solid #cda274;
  border-radius: 18px;
}

.Project-sec-1 span {
  width: auto;
  height: 21px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 125%;
}

.Project-sec-1 p {
  width: 219px;
  height: 65px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 125%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
}

.p-s-1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 26px 66px;
  grid-gap: 10px;
  gap: 10px;
  width: 219px;
  height: 65px;
  background: #cda274;
  border-radius: 18px;
}

.Project-sec-2 {
  width: 1201px;
  margin-top: 30px;
}

.pros {
  margin-top: 90px;
}

.Project-sec-3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0px;
  grid-gap: 20px;
  gap: 20px;
  width: 268px;
  height: 52px;
}

.Project-sec-3-1 span {
  width: 18px;
  height: 24px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-transform: capitalize;
  margin-left: 16px;
  margin-top: 13px;
}

.Project-sec-3-2 span {
  width: 18px;
  height: 24px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-transform: capitalize;
  margin-left: 16px;
  margin-top: 13px;
}

.Project-sec-3-1 {
  width: 52px;
  height: 100%;
  background: #f4f0ec;
  border-radius: 50%;
}

.Project-sec-3-2 {
  box-sizing: border-box;
  width: 52px;
  height: 100%;
  border: 1px solid #cda274;
  border-radius: 50%;
}

.Project-sec-3 img {
  width: 52px;
  height: 52px;
}


.B-S-1-2 {
  margin-left: 120px;
}

.B-S-1-1 {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
  height: 522px;
  border: 2px solid #e7e7e7;
  filter: drop-shadow(0px 10px 30px rgba(255, 255, 255, 0.25));
  border-radius: 60px;
}

.B-S-1-2 > span {
  width: 463px;
  height: 62px;
  font-family: "DM Serif Display";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 125%;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #292f36;
}

.B-S-1-2 > p {
  margin-top: 20px;
  width: 469px;
  height: 198px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #4d5053;
}

.B-S-1-3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  grid-gap: 214px;
  gap: 214px;
  width: 489px;
  height: 52px;
  margin-top: 10px;
}

.B-S-1-3 span {
  width: 223px;
  height: 33px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #4d5053;
}

.B-S-1-3 img {
  width: 52px;
  height: 52px;
}

.blog-3 {
  width: 100%;
  margin-top: 90px;
  align-items: center;
  align-content: center;
}

input::placeholder {
  width: 81px;
  height: 32px;
  left: 0px;
  top: 0px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #4d5053;
}

textarea::placeholder {
  width: 225px;
  height: 45px;
  left: 0px;
  top: 0px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #4d5053;
}

form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 1rem;
  margin-bottom: 50px;
}

.form-row__item {
  width: calc(50% - 0.5rem);
}

.c-letter span {
  width: 683px;
  display: flex;
  margin-left: 300px;
  font-family: "DM Serif Display";
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #292f36;
}

.c1 {
  margin-top: 150px;
  height: 100%;
  align-items: center;
  align-content: center;
  margin-left: 130px;
  margin-right: 130px;
}

.form-social {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 76px 32px;
  grid-gap: 25px;
  gap: 25px;
  width: 349px;
  height: 436.99px;
  background: #f4f0ec;
  border-radius: 50px;
}

.s1 img {
  width: 52px;
  height: 52px;
  border-radius: 50%;
}

.s2 img {
  width: 52px;
  height: 52px;
  border-radius: 50%;
}

.s3 img {
  width: 52px;
  height: 52px;
  border-radius: 50%;
}

.s1 span {
  width: 194px;
  height: 30px;
  left: 67px;
  top: 12px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
}

.s2 span {
  width: 170px;
  height: 30px;
  left: 67px;
  top: 94px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 150%;
  display: flex;
  align-items: center;
}

.s3 span {
  width: 193px;
  height: 30px;
  left: 67px;
  top: 176px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
}

.contact-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 26px 54px;
  grid-gap: 4px;
  gap: 4px;
  width: 230.14px;
  height: 75px;
  background: #292f36;
  box-shadow: 0px 10px 20px rgba(192, 192, 192, 0.35);
  border-radius: 18px;
}

.contact-btn span {
  grid-gap: 9px;
  gap: 9px;
  height: 23px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
}

.about-bt-class {
  width: 240px;
}

.contact-btn img {
  margin-left: 5px;
}

.Map {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-left: 130px;
  margin-right: 130px;
}

.P-Wrapper {
  margin-top: 100px;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-left: 130px;
  margin-right: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 154px 240px;
  grid-gap: 10px;
  gap: 10px;
  height: 671px;
  background: #f4f0ec;
  border-radius: 70px;
}

.P-Wrapper-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0px;
  grid-gap: 50px;
  gap: 50px;
  height: 363px;
}

.p-text span {
  width: 465px;
  height: 70px;
  font-family: "DM Serif Display";
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #292f36;
}

.p-text p {
  width: 718px;
  height: 66px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #4d5053;
  margin-top: 20px;
}

input#password {
  width: 100%;
  padding: 0;
  border: none;
  border-bottom: 2px solid #ccc;
  font-size: 16px;
  line-height: 24px;
  background: #f4f0ec;
}

.About-s-1 {
  margin-top: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-left: 130px;
  margin-right: 130px;
}

.About-s-2 {
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-left: 130px;
  margin-right: 130px;
}

.About-h span {
  width: 45px;
  height: 62px;
  font-family: "DM Serif Display";
  font-style: italic;
  font-weight: 400;
  font-size: 85px;
  line-height: 125%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #292f36;
}

.About-h h3 {
  width: 751px;
  height: 132px;
  font-family: "DM Serif Display";
  font-style: italic;
  font-weight: 400;
  font-size: 35px;
  line-height: 125%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: #292f36;
}

.About-h p {
  width: 209px;
  height: 39.25px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: #4d5053;
}

.a-b-class {
  margin-top: 40px;
}

.a-d-class {
  margin-top: 40px;
  width: 500px;
  height: 132px;
}

.About-s-3 {
  height: 868px;
  background: #f4f0ec;
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.a-s-3-text span {
  width: 547px;
  height: 126px;
  left: 326.6px;
  top: 0px;
  font-family: "DM Serif Display";
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 125%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: #292f36;
}

.About-s-4 {
  margin-top: 120px;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-left: 400px;
  margin-right: 400px;
}

.About-s-4 > span {
  width: 600px;
  font-family: "DM Serif Display";
  font-style: normal;
  font-weight: 400;
  font-size: 45px;
  line-height: 125%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: #292f36;
}

.form-wid {
  width: 100%;
  height: 380px;
}

.prof {
  margin-top: 120px;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-left: 90px;
  margin-right: 90px;
}

